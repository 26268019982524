// import { isLoggedIn } from '@utils/auth'
import Constants from '@constants'
import { requiredNoteRulesApi, dialogRulesApi, worklogRulesApi } from './api'

function createCustomRulesModule(moduleName) {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    fields: [],
    dialogFields: [],
    worklogAccess: '',
  }

  vuexModule.getters = {
    fields(state) {
      return state.fields
    },
    fieldRequiresNote(state) {
      return (field) => state.fields.indexOf(field) >= 0
    },
    dialogFields(state) {
      return state.dialogFields
    },
    fieldDialog(state) {
      return (field) => state.dialogFields.indexOf(field) >= 0
    },
    worklogAccess(state) {
      return state.worklogAccess
    },
  }

  vuexModule.mutations = {
    SET_FIELDS(state, fields) {
      state.fields = fields
    },
    SET_DIALOG_FIELDS(state, dialogFields) {
      state.dialogFields = dialogFields
    },
    SET_WORKLOG_ACCESS_RULE(state, worklogAccess) {
      state.worklogAccess = worklogAccess
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      // if (isLoggedIn()) {
      //   dispatch('fetch')
      // }
    },
    fetch({ commit, rootGetters }) {
      const availableModules = rootGetters['license/availableModules']
      if (availableModules.indexOf(moduleName) >= 0) {
        // perform any api request which should be called only when user is logged in
        requiredNoteRulesApi(moduleName).then((data) => {
          commit(
            'SET_FIELDS',
            data.items.map((i) => `${i}`)
          )
        })

        dialogRulesApi(moduleName).then((data) => {
          commit(
            'SET_DIALOG_FIELDS',
            data.items.map((i) => `${i}`)
          )
        })

        if (moduleName !== Constants.PURCHASE) {
          worklogRulesApi(moduleName).then((data) => {
            commit('SET_WORKLOG_ACCESS_RULE', data.item)
          })
        }
      }
    },

    fetchDialog({ commit }) {
      // perform any api request which should be called only when user is logged in
      dialogRulesApi(moduleName).then((data) => {
        commit(
          'SET_DIALOG_FIELDS',
          data.items.map((i) => `${i}`)
        )
      })
    },

    /**
     * destroy all states when user is logged out
     */
    // destroy({ commit }) {
    //   // perform state clean up here when user is logged out
    //   commit('SET_FIELDS', [])
    //   commit('SET_DIALOG_FIELDS', [])
    // },
  }

  return vuexModule
}

export const modules = {
  request: createCustomRulesModule(Constants.REQUEST),
  service_catalog: createCustomRulesModule(Constants.SERVICE_CATALOG),
  problem: createCustomRulesModule(Constants.PROBLEM),
  change: createCustomRulesModule(Constants.CHANGE),
  release: createCustomRulesModule(Constants.RELEASE),
  purchase: createCustomRulesModule(Constants.PURCHASE),
  project: createCustomRulesModule(Constants.PROJECT),
}
