import api from '@api'

export const transformRequiredNoteRules = (rule) => {
  return {
    id: rule.id,
    parentId: rule.refId,
    items: (rule.propKeysForNotes || []).concat(rule.customfieldForNotes || []),
  }
}
export const transformDialogRules = (rule) => {
  return {
    id: rule.id,
    parentId: rule.refId,
    items: (rule.propKeysForDialog || []).concat(
      rule.customfieldForDialog || []
    ),
  }
}

export const transformWorklogRules = (rule) => {
  return {
    id: rule.id,
    item: rule.managedKey || '',
  }
}

export function requiredNoteRulesApi(moduleName, _, parentId) {
  return api
    .get(`/${moduleName}/requirednoterule`, {
      params: {
        refId: parentId,
      },
    })
    .then(transformRequiredNoteRules)
}

export function dialogRulesApi(moduleName, _, parentId) {
  return api
    .get(`/${moduleName}/showdialogrule`, {
      params: {
        refId: parentId,
      },
    })
    .then(transformDialogRules)
}
export function worklogRulesApi(moduleName) {
  return api.get(`/${moduleName}/worklogrule`).then(transformWorklogRules)
}
